import { ACCOUNT_TABLE_ACTIONS, FILE_TABLE_ACTIONS } from "@recare/core/consts";
import { useTranslations } from "@recare/translations";
import LongMenu, { LongMenuOption } from "ds/components/Menus/LongMenu";
import RSButton, { RSButtonProps } from "ds/components/RSButton";
import { HorizontalLayout } from "ds/materials/layouts";
import { padding } from "ds/materials/metrics";
import { Dispatch, SetStateAction } from "react";
import { GeneralTableProps } from "..";

/**
 * The items in the menu are customizable. You can decide what to render and when to render.
 *
 * Here's how to add a new menu item to the general table menu
 *
 *
 * Step 1: Add an action type in the respective tsx.file (e.g. these are only actions for the Files Table)
 * export const FILE_TABLE_ACTION_HIGHLIGHT = "highlight" as const;
 * export const FILE_TABLE_ACTIONS = [
 *   ...
 *   FILE_TABLE_ACTION_HIGHLIGHT
 * ];
 *
 *
 * Step 2: Add it to options in the GeneralTable action property
 *
 *   actions={{
 *    options: (data) => [
 *   ...
 *   {
 *     id: FILE_TABLE_ACTION_HIGHLIGHT,
 *     label: "Highlight",
 *     icon: <HighlightIcon style={{ fontSize: FONT_SIZE_14 }} />,
 *     onClick: () => setAction({ type: FILE_TABLE_ACTION_HIGHLIGHT, data }),
 *   },
 *
 * ];
 *
 * Step 3: Using it in the table to display or not display the option
 *
 * <CatTable
 *    ...
 *    actions={{
        setAction,
        allowList: () => [FILE_TABLE_ACTION_HIGHLIGHT],
      }}
    />
 *
 *
 */

export const MENU_LIST_ACTIONS = [
  ...FILE_TABLE_ACTIONS,
  ...ACCOUNT_TABLE_ACTIONS,
] as const;

export type GeneralListActionKind = (typeof MENU_LIST_ACTIONS)[number];
export type GeneralListAction<Data> = {
  data: Data;
  type: GeneralListActionKind;
};
export type SetAction<Data> = Dispatch<
  SetStateAction<GeneralListAction<Data> | undefined>
>;

export type GeneralTableMenuItem = {
  icon: JSX.Element;
  id: (typeof MENU_LIST_ACTIONS)[number];
  label: string;
  onClick: () => void;
};

type ActionType = "menu" | "button";

type ButtonActionOption = Omit<RSButtonProps, "children"> & { label: string };
type MenuActionOption = LongMenuOption;

export type ActionMenuOption<RowActions extends GeneralListActionKind[]> = {
  id: RowActions[number];
  type?: ActionType;
} & (
  | ({ type?: "menu" } & MenuActionOption)
  | ({ type: "button" } & ButtonActionOption)
);

function isButtonAction<RowActions extends GeneralListActionKind[]>(
  action: ActionMenuOption<RowActions>,
): action is ButtonActionOption & { id: RowActions[number]; type: "button" } {
  return action.type === "button";
}

export function ActionMenu<
  Data extends { id: number | string },
  RowActions extends GeneralListActionKind[],
>({
  allowList,
  data,
  getOptions,
  getTestId,
  onActionMenuClick,
}: GeneralTableProps<Data, RowActions>["actions"] & { data: Data }) {
  const translations = useTranslations();

  const optionsResult = getOptions({ data, translations });
  let options =
    typeof optionsResult === "function" ? optionsResult() : optionsResult;
  if (typeof allowList === "function") {
    options = options.filter(({ id }) => allowList(data).includes(id));
  }

  const menuActions: MenuActionOption[] = [];
  const buttonActions: ButtonActionOption[] = [];

  options.forEach((option) => {
    if (isButtonAction(option)) {
      buttonActions.push(option);
    } else {
      menuActions.push(option);
    }
  });

  return (
    <HorizontalLayout gap={padding(0.25)} aligned justify="flex-end">
      {buttonActions.map(({ label, ...props }) => (
        <RSButton key={props.id} {...props}>
          {label}
        </RSButton>
      ))}
      {menuActions.length && (
        <LongMenu
          size="medium"
          options={menuActions}
          testId={getTestId?.(data)}
          onClick={(e) => onActionMenuClick?.(e, data)}
        />
      )}
    </HorizontalLayout>
  );
}
