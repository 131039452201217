import InfoIcon from "@mui/icons-material/Info";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useTranslations } from "@recare/translations";
import RSButton from "ds/components/RSButton";
import { HorizontalLayout } from "ds/materials/layouts";
import { margin } from "ds/materials/metrics";
import { FONT_WEIGHT_BOLD } from "ds/materials/typography";
import { CONFIRM_FAX_GDPR } from ".";

export const ConfirmFaxGDPRModal = ({
  handleConfirm,
  onClose,
  open,
}: {
  handleConfirm: () => void;
  onClose: () => void;
  open: boolean;
}) => {
  const translations = useTranslations();
  const theme = useTheme();
  return (
    <Dialog data-testid={CONFIRM_FAX_GDPR} open={open}>
      <DialogTitle>
        {translations.search.searchPageTables.modals.sendFax.title}
      </DialogTitle>
      <DialogContent>
        <HorizontalLayout margin={margin(0, 0, 2)}>
          <InfoIcon
            sx={{ margin: margin(0, 1, 0, 0) }}
            htmlColor={theme.palette.warning.main}
          />
          <DialogContentText fontWeight={FONT_WEIGHT_BOLD}>
            {translations.search.searchPageTables.modals.sendFax.warning}
          </DialogContentText>
        </HorizontalLayout>
        <DialogContentText>
          {translations.search.searchPageTables.modals.sendFax.subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={onClose}
          variant="text"
        >
          {translations.search.searchPageTables.modals.sendFax.buttonCancel}
        </RSButton>
        <RSButton
          color="primary"
          id="confirm"
          inverted
          loading="na"
          onClick={handleConfirm}
          variant="text"
        >
          {translations.search.searchPageTables.modals.sendFax.buttonConfirm}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
};
