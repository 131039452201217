import {
  AUCTION_STATUS_NOT_STARTED,
  SEARCH_ACTION_SEND_REQUEST,
  SEARCH_ACTION_START_MANUAL_SEARCH,
  TRACK_EVENTS,
} from "@recare/core/consts";
import { getSearchActions } from "@recare/core/model/auctions";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import { Auction, Careprovider } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { composeProgress } from "apollo/utils";
import { useFaxPatient } from "dsl/atoms/FaxPatient/useFaxPatient";
import {
  useStartAutomaticSearch,
  useStartManualSearch,
} from "dsl/atoms/PatientActions";
import useSearchAction from "dsl/hooks/useSearchAction";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { useTracking } from "react-tracking";
import { ConfirmFaxGDPRModal } from "./ConfirmFaxGDPR";
import { ConfirmSendModal } from "./ConfirmSend";
import { ConfirmSendFaxModal } from "./ConfirmSendFax";

export const CONFIRM_SEND = "confirm-send";
export const CONFIRM_FAX_GDPR = "confirm-fax-gdpr";
export const CONFIRM_SEND_FAX = "confirm-send-fax";

export type ContactAnywayModals =
  | typeof CONFIRM_SEND
  | typeof CONFIRM_FAX_GDPR
  | typeof CONFIRM_SEND_FAX;

const useHandleConfirmSend = ({
  auction,
  careprovider,
  recommendationId,
  refresh,
  sendFax,
  setModalOpen,
}: {
  auction: Auction;
  careprovider: Careprovider;
  recommendationId: number;
  refresh: () => void;
  sendFax?: boolean;
  setModalOpen: React.Dispatch<
    React.SetStateAction<ContactAnywayModals | null>
  >;
}) => {
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const notify = useNotification();
  const { [SEARCH_ACTION_START_MANUAL_SEARCH]: canStartManualSearch } =
    getSearchActions(auction);

  const [startManualSearch, startManualSearchProgress] = useStartManualSearch({
    auction,
  });
  const [startAutomaticSearch, startAutomaticSearchProgress] =
    useStartAutomaticSearch({
      auction,
    });
  const [sendRequests, sendRequestsProgress] = useSearchAction({
    actionType: SEARCH_ACTION_SEND_REQUEST,
  });

  const { faxAuctionRequest } = useFaxPatient();

  async function handleConfirm() {
    try {
      trackEvent({
        name: TRACK_EVENTS.CONTACT_ANYWAY_MODAL_CONFIRM_BUTTON_CLICKED,
        careprovider_id: careprovider.id,
      });

      //   start search if not started yet
      if (auction.status === AUCTION_STATUS_NOT_STARTED) {
        if (canStartManualSearch) {
          await startManualSearch();
        } else {
          await startAutomaticSearch();
        }
      }

      //   send requests
      await sendRequests({
        auction,
        context: { requests: [{ id: recommendationId }] },
        onCompleted: async (auctionWithRequests) => {
          setModalOpen(null);

          notify({
            message:
              translations.search.addProvider.successfullySentSingleRequest,
            type: "success",
          });

          if (!sendFax || !auctionWithRequests?.requests?.length) {
            return;
          }

          //   send faxes if request is for a faxable provider
          try {
            const request = auctionWithRequests.requests?.find(
              (request) => request?.careprovider?.id === careprovider.id,
            );

            if (!request) {
              return;
            }

            await faxAuctionRequest(auction, request);
          } catch (err) {
            console.error(
              `failed to send fax from filtered provider in search merge page - ${getErrorMessage(
                err,
              )}`,
            );
          }
        },
      });
    } catch (err) {
      console.error(`error contacting anyway: ${getErrorMessage(err)}`);
    } finally {
      refresh();
    }
  }

  return {
    loading: composeProgress([
      sendRequestsProgress,
      startManualSearchProgress,
      startAutomaticSearchProgress,
    ]),
    handleConfirm,
  };
};

export function ContactAnywayConfirmationModal({
  auction,
  careprovider,
  modalOpen,
  recommendationId,
  refresh,
  sendFax,
  setModalOpen,
}: {
  auction: Auction;
  careprovider: Careprovider;
  modalOpen: ContactAnywayModals | null;
  recommendationId: number;
  refresh: () => void;
  sendFax?: boolean;
  setModalOpen: React.Dispatch<
    React.SetStateAction<ContactAnywayModals | null>
  >;
}) {
  const { handleConfirm, loading } = useHandleConfirmSend({
    auction,
    careprovider,
    recommendationId,
    refresh,
    sendFax,
    setModalOpen,
  });

  const onClose = () => setModalOpen(null);

  switch (modalOpen) {
    case CONFIRM_SEND: {
      return (
        <ConfirmSendModal
          handleConfirm={handleConfirm}
          queryProgress={loading}
          onClose={onClose}
          open
        />
      );
    }
    case CONFIRM_FAX_GDPR: {
      return (
        <ConfirmFaxGDPRModal
          handleConfirm={() => setModalOpen(CONFIRM_SEND_FAX)}
          onClose={onClose}
          open
        />
      );
    }
    case CONFIRM_SEND_FAX: {
      return (
        <ConfirmSendFaxModal
          providerName={careprovider.name ?? ""}
          handleConfirm={handleConfirm}
          queryProgress={loading}
          onClose={onClose}
          open
        />
      );
    }
    default: {
      return null;
    }
  }
}
