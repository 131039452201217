import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { TourElement } from "@recare/core/types";
import {
  GeneralTableProps,
  StyledInnerCellWrapper,
} from "ds/components/Tables/GeneralTable";
import {
  TABLE_HOVER_GREY,
  TABLE_SELECTED,
  TABLE_SELECTED_HOVER,
} from "ds/materials/colors";
import { margin } from "ds/materials/metrics";
import { CSSProperties, useEffect, useState } from "react";
import {
  COLUMN_TYPE_DEFAULT_ACTION,
  ColumnKinds,
  COLUMN_TYPE_DEFAULT_STRING as DEFAULT_COLUMN_TYPE,
  GeneralTableColumn,
  getCellComponent,
} from "./CustomCells";
import { ActionMenu, GeneralListActionKind } from "./MenuActions";

const useStyles = makeStyles(() => ({
  /* Styles applied to the root element. */
  root: {
    // Default root styles
    color: "inherit",
    display: "table-row",
    verticalAlign: "middle",
    // Disabling focus ring for mouse, touch and keyboard users.
    outline: 0,
    "&.Mui-selected": {
      backgroundColor: TABLE_SELECTED,
    },
    "&.Mui-selected:hover": {
      backgroundColor: TABLE_SELECTED,
    },
  },
  hover: {
    "&:hover": {
      backgroundColor: TABLE_HOVER_GREY,
      cursor: "pointer",
    },
    "&.Mui-selected:hover": {
      backgroundColor: TABLE_SELECTED_HOVER,
    },
  },
}));

export function convertAlignToJustifyContent<Data, Kind extends ColumnKinds>(
  align: GeneralTableColumn<Data, Kind>["align"],
): CSSProperties["justifyContent"] {
  return align === "right" ? "flex-end" : "flex-start";
}

async function getCells<Data>({
  columns,
  rowData,
}: {
  columns: GeneralTableColumn<NonNullable<Data>, ColumnKinds>[];
  rowData: Data;
}) {
  const columnPromises = columns.map(
    async (
      {
        getProps,
        type = DEFAULT_COLUMN_TYPE,
        width,
        align,
        disableDivider,
        title,
      },
      index,
    ) => {
      const Component = getCellComponent({ type });
      const props =
        typeof getProps === "function"
          ? await getProps(rowData as NonNullable<Data>)
          : { [getProps]: rowData[getProps as keyof Data] };

      disableDivider =
        index === 0 || typeof title !== "string" || disableDivider;
      return (
        <TableCell
          data-type={type}
          key={index}
          align={align}
          sx={{
            width,
            marginLeft: index !== 0 ? margin(0.5) : undefined,
          }}
        >
          <StyledInnerCellWrapper
            isTransparentDivider
            disableDivider={disableDivider}
            boxSizing="border-box"
            aligned
            justify={convertAlignToJustifyContent(align)}
            width="100%"
          >
            <Component {...(props as any)} />
          </StyledInnerCellWrapper>
        </TableCell>
      );
    },
  );
  const result = await Promise.all(columnPromises);
  return result;
}

export function useGetTableBody<
  Data extends { id: number | string },
  RowActions extends GeneralListActionKind[],
>({
  actions,
  columns,
  data,
  dependencies = [],
  getA11yRowProps,
  id,
  isSelected,
  onRowClick,
  rowClickDisabled,
  tour,
}: Pick<
  GeneralTableProps<Data, RowActions>,
  | "actions"
  | "columns"
  | "data"
  | "dependencies"
  | "getA11yRowProps"
  | "id"
  | "isSelected"
  | "onRowClick"
  | "rowClickDisabled"
> & {
  tour?: TourElement;
}) {
  const classes = useStyles();
  const visibleColumns = columns.filter(({ isHidden }) => !isHidden);
  const [tableBody, setTableBody] = useState<JSX.Element[] | null>(null);

  async function getTableBody() {
    if (!data) return null;

    const rowPromises = data.map(async (rowData, index) => {
      const disabledRowClick = rowClickDisabled?.({ data: rowData, index });
      const rowClickable = onRowClick && !disabledRowClick;
      const ariaProps = getA11yRowProps?.({ data: rowData, index }) || {};
      const selected = isSelected?.(rowData.id);

      return (
        <TableRow
          aria-checked={isSelected ? !!selected : undefined}
          aria-disabled={onRowClick ? disabledRowClick : undefined}
          classes={classes}
          data-testid={`${id}-row-${rowData.id}`}
          hover={rowClickable}
          key={index}
          onClick={(event) => {
            if (disabledRowClick) return;
            onRowClick?.({ rowData, event });
          }}
          selected={selected}
          tabIndex={0}
          {...ariaProps}
          {...tour}
        >
          {await getCells({ rowData, columns: visibleColumns })}
          {actions ? (
            <TableCell
              align="right"
              data-type={COLUMN_TYPE_DEFAULT_ACTION}
              onClick={(e) => e.stopPropagation()}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: actions.width,
                marginLeft: margin(0.5),
              }}
              {...actions.tour?.(index)}
            >
              <ActionMenu<Data, RowActions> {...actions} data={rowData} />
            </TableCell>
          ) : null}
        </TableRow>
      );
    });

    const result = await Promise.all(rowPromises);
    return result;
  }

  useEffect(() => {
    (async () => {
      try {
        const tableBody = await getTableBody();
        setTableBody(tableBody);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [data, ...dependencies]);

  return { tableBody, visibleColumns };
}
